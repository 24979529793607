import { wait } from '@nexdynamic/squeegee-common';
import type { Router } from 'aurelia-router';
import { ApplicationState } from '../ApplicationState';
import { AureliaReactComponentDialog } from '../Dialogs/AureliaReactComponentDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import type { Subscription } from '../Events/DataRefreshedEvent';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import type { GcBankDataConnectedDialogOptions } from '../ReactUI/ConnectedServices/GcBankData/views/GcBankDataConnectedDialog';
import { GcBankDataConnectedDialog } from '../ReactUI/ConnectedServices/GcBankData/views/GcBankDataConnectedDialog';
import GcBankDataDialog from '../ReactUI/ConnectedServices/GcBankData/views/GcBankDataDialog';
import { Api } from '../Server/Api';
import { ConnectedServiceConfig } from './ConnectedServiceConfig';
import { ConnectedServicesService } from './ConnectedServicesService';
import type { IConnectedServiceInfo } from './IConnectedServiceInfo';

export class ConnectedServices {
    protected hasAdvancedOrAbove = ApplicationState.hasAdvancedOrAbove;
    protected hasUltimateOrAbove = ApplicationState.hasUltimateOrAbove;
    protected isConnected: boolean = Api.isConnected;
    protected hasBankConnected = false;
    protected providers: Array<IConnectedServiceInfo>;
    protected _dataChangedSub: Subscription;
    protected router: Router;

    protected providerId: string;
    public async activate(params: any, routeConfig: any) {
        this.router = routeConfig.navModel.router;

        await this.init();

        this.providerId = params.provider;
    }

    constructor() {
        this.init();
    }

    public async init() {
        this._dataChangedSub = DataRefreshedEvent.subscribe(async (x: DataRefreshedEvent) => {
            if (x.hasAnyType('settings')) {
                this.providers = await ConnectedServicesService.getProviders();
            }
        });

        const providerData = await ConnectedServicesService.getProviders();
        const devOnly = { xero: true, square: true } as Record<string, boolean | undefined>;

        this.providers = (providerData || []).filter(x => !devOnly[x.id]);
        this.hasBankConnected = this.providers.some(x => x.id.startsWith('gcbankdata'));
    }

    async attached() {
        await wait(1000);
        if (!this.providerId) return;
        const provider = this.providers.find(x => x.id === this.providerId);
        if (!provider) return;

        if (provider.id.includes('gcbankdata')) {
            const connectedBanks = this.providers.filter(x => x.status === 'connected' && x.id.includes('gcbankdata'));

            AureliaReactComponentDialog.show<any, GcBankDataConnectedDialogOptions>({
                component: GcBankDataConnectedDialog,
                componentProps: { connectedProvider: provider, allConnectedProviders: connectedBanks },
                dialogTitle: 'connected-services.gc-bank-data-connect',
                isSecondaryView: true,
            });
        } else {
            this.openProvider(provider);
        }
    }

    public dispose() {
        this._dataChangedSub && this._dataChangedSub.dispose();
    }

    async openProvider(provider: IConnectedServiceInfo) {
        const providerDialog = new ConnectedServiceConfig(provider);
        await providerDialog.show(DialogAnimation.SLIDE_IN);
    }

    async openConnectBank() {
        AureliaReactComponentDialog.show({
            dialogTitle: 'connected-services.gc-bank-data-connect',
            component: GcBankDataDialog,
            isSecondaryView: true,
        });
    }
}
