import type {
    ConnectedServiceSettings,
    PaymentAccountProviders,
    StoredObject,
    TransactionProvider,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { PaymentAccount } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import { CustomDialogBase } from '../../Dialogs/CustomDialogBase';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { TextDialog } from '../../Dialogs/TextDialog';
import { Logger } from '../../Logger';
import { TransactionService } from '../../Transactions/TransactionService';
import type { IPaymentAccountMatch } from './IPaymentAccountMatch';

export class PaymentAccountMatchDialog extends CustomDialogBase<Array<IPaymentAccountMatch>> {
    protected matchedItems: Array<IPaymentAccountMatch> = [];
    protected accounts: Readonly<Array<PaymentAccount>> = [];
    protected totalUnmatched = 0;
    protected totalConfirmed = 0;
    protected showUnmatched = true;
    protected showConfirmed = false;
    protected filteredMatchedItems: Array<IPaymentAccountMatch> = [];
    constructor(
        public menuTitle: TranslationKey | string,
        protected providerAccounts: Array<PaymentAccount>,
        public provider: PaymentAccountProviders
    ) {
        super('paymentMatchDialog', '../ConnectedServices/PaymentAccounts/PaymentAccountMatchDialog.html', '', {
            isSecondaryView: true,
            okLabel: '',
            cancelLabel: '',
            cssClass: 'payment-match-dialog details-dialog no-nav-shadow',
        });
    }

    // eslint-disable-next-line require-await
    async init() {
        try {
            this.accounts = Data.all<PaymentAccount>('paymentaccounts');
            this.autoMatchAccounts(this.providerAccounts);
            this.updateTotals();
            this.filter();
        } catch (error) {
            Logger.error(`Error during initialise the customer match dialog`, error);
        }
    }

    protected toggleTab(to: 'unmatched' | 'confirmed') {
        switch (to) {
            case 'unmatched':
                this.showUnmatched = !this.showUnmatched;
                break;
            case 'confirmed':
                this.showConfirmed = !this.showConfirmed;
                break;
        }
        this.filter();
    }

    private filter() {
        this.filteredMatchedItems = this.matchedItems.filter(
            x => x.providerAccount && (this.showUnmatched || x.status !== 'unmatched') && (this.showConfirmed || x.status !== 'confirmed')
        );
    }

    private autoMatchAccounts(activeThirdParty: Array<PaymentAccount>) {
        this.matchedItems = [];

        const itemsToProcess = activeThirdParty.slice();

        if (!itemsToProcess || !itemsToProcess.length) return;

        // Process ignored and already matched
        for (let i = itemsToProcess.length - 1; i > -1; i--) {
            const providerAccount = itemsToProcess[i];

            const matched = this.findExactMatch(providerAccount, this.accounts);

            if (matched) {
                this.matchedItems.push(matched);
                continue;
            }

            const unmatchedAccounts = this.accounts.filter(x => !x.externalIds?.[this.provider]);
            const fuzzyMatch = this.findFuzzyMatch(providerAccount, unmatchedAccounts);

            this.matchedItems.push(fuzzyMatch);
        }
    }

    private findExactMatch(providerAccount: PaymentAccount, accounts: Readonly<Array<PaymentAccount>>): IPaymentAccountMatch | undefined {
        try {
            for (const account of accounts) {
                if (account._id === providerAccount._id) {
                    return { providerAccount: providerAccount, squeegeeAccount: account, status: 'confirmed', possibleMatches: [] };
                }
                if (account.externalIds?.[this.provider] && account.externalIds?.[this.provider] === providerAccount._id) {
                    return { providerAccount: providerAccount, squeegeeAccount: account, status: 'confirmed', possibleMatches: [] };
                }
            }
        } catch (error) {
            Logger.error(`Error during find match in the match dialog`, error);
            return { providerAccount: providerAccount, squeegeeAccount: undefined, status: 'unmatched', possibleMatches: [] };
        }
    }
    private findFuzzyMatch(providerAccount: PaymentAccount, accounts: Array<PaymentAccount>): IPaymentAccountMatch {
        try {
            const possibleMatches: Array<PaymentAccount> = [];

            for (const account of accounts) {
                possibleMatches.push(account);
            }

            return { providerAccount: providerAccount, squeegeeAccount: undefined, status: 'unmatched', possibleMatches };
        } catch (error) {
            Logger.error(`Error during find match in the customer match dialog`, error);
            return { providerAccount: providerAccount, squeegeeAccount: undefined, status: 'unmatched', possibleMatches: [] };
        }
    }

    private async _migrateTransactionIds(
        oldProviderKey: PaymentAccountProviders,
        paymentAccount: PaymentAccount,
        newProviderKey: PaymentAccountProviders,
        newProviderId: string
    ) {
        const trans = TransactionService.getAccountTransactions(oldProviderKey as unknown as TransactionProvider, paymentAccount._id);
        const dataToUpdate: Array<StoredObject> = [];

        // Update all the transactions to use the new truelayer id
        trans.forEach(tran => {
            if (!tran.externalIds?.[oldProviderKey as TransactionProvider]) return;
            tran.externalIds[newProviderKey as TransactionProvider] = tran.externalIds[oldProviderKey as TransactionProvider];
            delete tran.externalIds[oldProviderKey as TransactionProvider];
            dataToUpdate.push(tran);
        });

        // Remove the old truelayer id from the payment account.

        if (paymentAccount.externalIds?.[oldProviderKey]) delete paymentAccount.externalIds[oldProviderKey];
        if (!paymentAccount.externalIds) paymentAccount.externalIds = {};
        paymentAccount.externalIds[this.provider] = newProviderId;
        await Data.put(dataToUpdate);
        await Data.put(paymentAccount);

        // Migrate the settings to the new provider id.
        const settings = ApplicationState.getSetting<ConnectedServiceSettings>('global.connected-services', {});
        settings[newProviderKey] = JSON.parse(JSON.stringify(settings[oldProviderKey]));
        await ApplicationState.setSetting('global.connected-services', settings);
    }

    public async assign(matchedItem: IPaymentAccountMatch, selected?: PaymentAccount) {
        if (!matchedItem.providerAccount) return;

        if (!selected) {
            const newAccountDialog = new TextDialog(
                'Create Payment Account' as TranslationKey,
                'Account name' as TranslationKey,
                matchedItem.providerAccount.name,
                ''
            );
            const newAccountName = await newAccountDialog.show(DialogAnimation.SLIDE_UP);
            if (newAccountDialog.cancelled || !newAccountName) return;

            const newAccount = new PaymentAccount();
            newAccount.name = newAccountName;
            newAccount.externalIds = { [this.provider]: matchedItem.providerAccount._id };
            newAccount.provider = matchedItem.providerAccount.provider;
            newAccount.accountNumber = matchedItem.providerAccount.accountNumber;
            newAccount.sortcode = matchedItem.providerAccount.sortcode;
            newAccount.providerlogoUrl = matchedItem.providerAccount.providerlogoUrl;
            newAccount.currency = matchedItem.providerAccount.currency;
            await Data.put(newAccount, true);
            this.accounts = Data.all<PaymentAccount>('paymentaccounts');
        } else {
            if (!selected.externalIds) selected.externalIds = {};
            // Check if account is already associated with a provider, if so offer to migrate to this account.

            // this account is already connected with a provider
            const otherProvidersNotThisOne = Object.keys(selected.externalIds).find(x => x !== this.provider);
            if (otherProvidersNotThisOne) {
                const migrate = await new Prompt(
                    'Migrate Account' as TranslationKey,
                    'This account is already connected to another provider. Would you like to migrate it to this provider?' as TranslationKey
                ).show(DialogAnimation.SLIDE_UP);
                if (!migrate) return;

                await this._migrateTransactionIds(
                    otherProvidersNotThisOne as PaymentAccountProviders,
                    selected,
                    this.provider,
                    matchedItem.providerAccount._id
                );
            } else {
                selected.externalIds[this.provider] = matchedItem.providerAccount._id;
                await Data.put(selected, true);
            }
        }

        //   this.removeAlreadyAssigned(selectedCustomer);
        matchedItem.squeegeeAccount = selected;
        //   if (!selectedCustomer.externalIds) selectedCustomer.externalIds = {};
        // if (matchedItem.providerAccount.externalIds?.[this.provider]) {
        //     selectedCustomer.externalIds[this.provider] = matchedItem.providerAccount.externalIds?.[this.provider];
        // }
        this.match(matchedItem);
    }

    public async remove(matchedItem: IPaymentAccountMatch) {
        if (matchedItem.squeegeeAccount?.externalIds?.[this.provider]) {
            const squeegeeAccountToUnmatch = Data.get(matchedItem.squeegeeAccount._id);
            if (squeegeeAccountToUnmatch?.externalIds) {
                delete squeegeeAccountToUnmatch.externalIds[this.provider];
                await Data.put(squeegeeAccountToUnmatch, true);
            }
        }
        matchedItem.squeegeeAccount = undefined;
        matchedItem.status = 'unmatched';

        this.autoMatchAccounts(this.providerAccounts);
        this.updateTotals();
        this.filter();
    }

    public match(matchedItem: IPaymentAccountMatch) {
        matchedItem.status = 'confirmed';

        this.autoMatchAccounts(this.providerAccounts);
        this.updateTotals();
        this.filter();
    }

    // eslint-disable-next-line require-await
    public async getResult() {
        return this.matchedItems;
    }

    private updateTotals() {
        this.totalUnmatched = 0;
        this.totalConfirmed = 0;

        for (const match of this.matchedItems) {
            if (!match.providerAccount) continue;

            if (match.status === 'confirmed') this.totalConfirmed++;
            else this.totalUnmatched++;
        }
        this.matchedItems.sort((x, y) => {
            const xName = x.providerAccount?.name || x.squeegeeAccount?.name || '';
            const yName = y.providerAccount?.name || y.squeegeeAccount?.name || '';
            return xName > yName ? 1 : xName < yName ? -1 : 0;
        });
    }
}
