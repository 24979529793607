import * as leaflet from 'leaflet';
import { ApplicationState } from '../../ApplicationState';

export const baseLayers = {
    'Normal Day': 'style=explore.day',
    'Normal Day Grey': 'style=lite.day',
    'Normal Day Transit': 'style=explore.day&features=public_transit:all_systems',
    'Normal Night': 'style=explore.night',
    'Normal Night Grey': 'style=lite.night',
    'Pedestrian Day': 'style=explore.day',
    'Pedestrian Night': 'style=explore.night',
    'Carnav Day Grey': 'style=lite.day',
    'Reduced Day': 'style=lite.day',
    'Reduced Night': 'style=lite.night',
    'Normal Traffic Day': 'style=lite.day',
    'Normal Night Transit': 'style=explore.night&features=public_transit:all_systems',
    'Terrain Day': 'style=topo.day',
    'Satellite Day': 'style=satellite.day',
    'Hybrid Day': 'style=explore.satellite.day',
    'Hybrid Grey Day': 'style=lite.satellite.day',
    'Hybrid Day Transit': 'style=explore.satellite.day&features=public_transit:all_systems',
} as const;

export const getMapBaseLayers = () => {
    const lang = ApplicationState.account.language.slice(0, 2) || 'en';

    const layers = Object.entries(baseLayers).reduce((acc, [name, style]) => {
        acc[name as keyof typeof baseLayers] = new leaflet.TileLayer(
            `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?${style}&size=512&lang=${lang}&ppi=400&apiKey=U7VG3YF0RPY6AJHRwvdWbRN4vfw3N-4mRTnRq8fHj-k`,
            { maxZoom: 18 }
        );
        return acc;
    }, {} as Record<keyof typeof baseLayers, leaflet.TileLayer>);

    return layers;
};
