import type { TCellValue, TranslationKey, TReportType } from '@nexdynamic/squeegee-common';
import { Setting, TableData } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Logger } from '../../Logger';
import { ReportsService } from '../ReportsService';

export class Reports {
    protected report: Array<TableData>;

    private _serverSideReportsSettingId = Setting.getId(ApplicationState.account.uuid, 'beta-features.server-side-reports');
    private _dataRefreshedEvent: any;

    public async attached() {
        await this.loadReports();

        this._dataRefreshedEvent = DataRefreshedEvent.subscribe<DataRefreshedEvent>(async e => {
            if (!e.updatedObjects[this._serverSideReportsSettingId]) return;

            this.report = [];
            this.loadReports();
        });
    }

    public async detached() {
        this._dataRefreshedEvent?.dispose();
    }

    public async loadReports() {
        new LoaderEvent(true, true);
        try {
            const reportsTable = new TableData(
                '' as TranslationKey,
                [ApplicationState.localise('reports.title'), 'type'],
                [],
                undefined,
                (row: Array<TCellValue> & { click?: () => void }) => row.click && row.click()
            );

            const executableReports = await ReportsService.getExecutableReports();

            for (const report of executableReports) {
                const isViewingAsSqueegee = report.name.includes('(VIEWING AS SQUEEGEE) ');

                const name = ApplicationState.localise(report.name.replace('(VIEWING AS SQUEEGEE) ', '') as TranslationKey);

                const prefix = isViewingAsSqueegee ? '🕵️ ' : ' ';

                const reportRow: Array<TCellValue> & { click?: () => void } = [
                    (report.logoImg + prefix + name + report.legacyBadge) as TranslationKey,
                    report.type,
                ];

                reportRow.click = report.show;

                reportsTable.addRow(reportRow);
            }

            reportsTable.rows.sort((x, y) => (x[1] < y[1] ? -1 : x[1] === y[1] ? 0 : 1));

            for (const row of reportsTable.rows) {
                row[1] = ApplicationState.localise(`reports-type.${row[1] as TReportType}`);
            }

            this.report = [reportsTable];
        } catch (error) {
            Logger.error('Error in attached on Reports', error);
        }
        new LoaderEvent(false);
    }
}
