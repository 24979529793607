import type {
    ConnectedServiceSettings,
    CustomerProvider,
    IFabAction,
    PaymentAccountProviders,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';

import { ConnectedServicesService } from '../../ConnectedServices/ConnectedServicesService';
import { FabWithActions } from '../../FabWithActions';
import { Utilities } from '../../Utilities';
import { AccountsLedgerService } from '../AccountsLedgerService';
export class ChartOfAccounts {
    async attached() {
        FabWithActions.register(this.getActions());
    }

    public detached() {
        FabWithActions.unregister();
    }

    private getActions(): Array<IFabAction> {
        const fabActions: Array<IFabAction> = [];

        if (ApplicationState.hasAdvancedOrAbove) {
            fabActions.push({
                tooltip: `Create account` as TranslationKey,
                actionType: 'action-new-job',
                handler: () => {
                    AccountsLedgerService.createAccount();
                },
                roles: ['Owner', 'Admin'],
            });

            const settings = ApplicationState.getSetting<ConnectedServiceSettings>('global.connected-services');

            if (settings) {
                for (const provider in settings) {
                    const setting = settings[provider as CustomerProvider];
                    if (!setting?.provides?.length) continue;
                    if (provider.startsWith('truelayer')) continue;
                    if (setting.provides.indexOf('paymentaccounts') > -1) {
                        const providerLabel = ApplicationState.localise(`providers.${provider.split('.')[0]}-name` as TranslationKey);
                        fabActions.push({
                            tooltip: `Sync accounts with ${Utilities.toTitleCase(providerLabel)}` as unknown as TranslationKey,
                            actionType: 'action-download',
                            handler: () => {
                                ConnectedServicesService.importPaymentAccounts(provider as PaymentAccountProviders);
                            },
                            roles: ['Owner', 'Admin'],
                        });
                    }
                }
            }
        }

        return fabActions;
    }
}
